<template>
  <div
    :class="classMessageType">
    <div class="message__bubble">
      <div class="message__notification">
        <div
          v-if="showIcon"
          class="message__notification-icon">
          <component :is="iconComponent"/>
        </div>
        <span
          :class="classTextContent"
          v-html="notificationText"></span>
        <div
          v-if="!hideTimestamp"
          class="message__notification-trail">{{ timestamp }}
        </div>
      </div>
    </div>
    <div class="message__notification-separator"></div>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
    hasNext: {
      default: false,
    },
  },
  computed: {
    classMessageType() {
      return [
        'message',
        'message--item',
        {
          'message--has-next': this.hasNext,
        },
      ];
    },
    classTextContent() {
      return [
        'message__notification-content',
        {
          'message__notification-content--uppercase': this.hasRelativeTimestamp,
        },
      ];
    },
    timestamp() {
      return this.globalFormatTime(this.message.createdAt);
    },
    notificationType() {
      return this.message.type;
    },
    isStatusChange() {
      return this.notificationType === 'statusChange';
    },
    statusChange() {
      return this.message.statusChange ?? {};
    },
    statusTo() {
      return this.statusChange.to ?? 0;
    },
    statusLabel() {
      return `Conversación cambió a <b>${this.getStatusFromId(this.statusTo)}</b>`;
    },
    isTransfer() {
      return this.notificationType === 'transfer';
    },
    transfer() {
      return this.message.transfer ?? {};
    },
    transferLabel() {
      // TODO implement
      return 'Conversación transferida';
    },
    showIcon() {
      return this.isStatusChange || this.isTransfer;
    },
    iconComponent() {
      if (this.isStatusChange) {
        return 'reload-icon';
      }

      if (this.isTransfer) {
        return 'shuffle-icon';
      }

      return '';
    },
    notificationText() {
      if (this.isStatusChange) {
        return this.statusLabel;
      }

      if (this.isTransfer) {
        return this.transferLabel;
      }

      if (this.hasRelativeTimestamp) {
        return this.globalFormatRelativeDateTime(this.message.relativeTimestamp, false, true);
      }

      return '';
    },
    hasRelativeTimestamp() {
      return !!this.message.relativeTimestamp;
    },
    hideTimestamp() {
      return !!this.message.hideTimestamp;
    },
  },
  methods: {
    getStatusFromId(id) {
      // TODO translate
      switch (id) {
        case 1:
          return 'No asignada';
        case 2:
          return 'Asignada';
        case 3:
          return 'En proceso';
        case 4:
          return 'Resuelta';
        case 5:
          return 'Cancelada';
        case 6:
          return 'Fuera de horario';
        case 7:
          return 'Agentes desconectados';
        case 8:
          return 'IVR';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
